@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800&display=swap');

/* Styles for the slide menu */
.bm-menu {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f4eac6;
  padding: 2rem;
  font-size: 1.5rem;
  width: 90%;
}

/* Styles for the menu items */
.bm-item {
  display: block;
  padding: 0.8rem;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
  text-align: center;
}

.bm-item:hover {
  color: #ff5500;
  cursor: pointer;
}

/* Styles for the close button */
.bm-cross-button {
  left: 14.5rem ;
  height: 2rem;
  width: 2rem;
}

.bm-cross {
  background: #ffffff;
}

/* Styles for the hamburger menu icon */
.bm-burger-button {
  position: fixed;
  width: 3rem;
  height: 2rem;
  top: 1.6rem;
  left: 1.6rem;
}

.bm-burger-bars {
  background: #b13131ee;
  box-shadow: 3px 3px 5px #333;
}

/* Styles for the overlay when the menu is open */
.bm-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}

/* Styles for About Modal */
.custom-modal {
  position: absolute;
  top: 15%;
  left: 20%; 
  width: 60vw; 
  height: 75vh; 
  border-radius: 15px;
  background-color: #6b7b5a; 
  font-family: 'Nunito', sans-serif;
  color: #f4eac6;
  opacity: 95%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
#modal-content {
  flex-shrink: 1;
  overflow: scroll;
}

#modal-content > h2 {
  font-weight: 700;
  font-size: 30px;
  margin: .5em; 
}

#modal-content > span {
  position: absolute;
  top: 2%;
  right: 3%; 
  cursor: pointer;
  color: white;
  font-size: 2vw; 
  font-family: Arial, Helvetica, sans-serif;
}

#modal-content > p {
  margin: 5px 40px 5px 40px;
  font-size: 1.8vw;
  text-align: center;
}

hr {
  border: none;
  border-top: 3px dashed #333;
}

@media (max-aspect-ratio: 1/1) {
  /* Styles for viewport height > width */
  /* Styles for About Modal */
  .custom-modal {
    position: fixed;
    top: 20%;
    left: 20%;  
    width: 60vw; 
    height: 70vh; 
    border-radius: 15px;
    background-color: #8f7398; 
    color: #f4eac6;
    font-family: 'Nunito', sans-serif;
    opacity: 95%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  .custom-modal > h2 {
    margin-bottom: 10%;
    font-family: 'Nunito', sans-serif;
  }

  .custom-modal > span {
    position: absolute;
    top: 2%;
    right: 3%; 
    cursor: pointer;
    color: white;
    font-size: 2.75vw; 
    font-family: 'Nunito', sans-serif;
  }
  
  .custom-modal > p {
    margin: 5px 20px 5px 20px;
    font-size: 1.75vh;
    text-align: center;
  }
}
